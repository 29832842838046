import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { logout } from "./../redux/actions/auth-actions/logoutAction";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoins } from '@fortawesome/free-solid-svg-icons'
import { faWallet } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faImage } from '@fortawesome/free-solid-svg-icons'
import { faCartPlus } from '@fortawesome/free-solid-svg-icons'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faAddressCard } from '@fortawesome/free-solid-svg-icons'
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons'
import { fetchCartProducts } from "../redux/actions/cart-actions/fetchCartProducts";
import { Row, Col } from "react-bootstrap";
import { useIdleTimer } from 'react-idle-timer'
import Diversity3Icon from '@mui/icons-material/Diversity3';

function MainNavbar() {
  const { user, auth, loading } = useSelector(state => state.userrr);
  const { cart } = useSelector(state => state.carttt);

//---------------logout and fetch cart concerns when product deleted-------------------------------------------------

// token generated will last for 12 hours
// however if screen idle for 1 hour, it will logout and go to login page (work also on device off)
// after 1 hour idle, fetch cart (if have deleted product) also no problem already
// only will have problem if user only stay at cart page and only go into cart page only after awhile for 12 hours
// so delete product after 12 hours when stock set to 0 and status:closed will not have problem at all

// if close tab, useIdleTimer didn't work, but after 12 hours, when to go page that need login will redirect to 404 page
// close tab doesn't affect fetch cart (if have deleted product), coz everything will refreshed

  const handleOnIdle = event => {
    // console.log('user is idle', event)
    // console.log('last active', getLastActiveTime())
    const token = localStorage.getItem("token");
    if (token){
      dispatch(logout());
      history.push("/login");
    }
  }

  // const handleOnActive = event => {
  //   console.log('user is active', event)
  //   console.log('time remaining', getRemainingTime())
  // }

  // const handleOnAction = event => {
  //   console.log('user did something', event)
  // }

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 60,
    onIdle: handleOnIdle,
    // onActive: handleOnActive,
    // onAction: handleOnAction,
    debounce: 5000
  })

//--------------------------------------------------------------------

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCartProducts());
  }, [dispatch]);


  let history = useHistory();

  const logoutUser = () => {
    dispatch(logout());
    history.push("/");
  };
  
  /* Dashboard link */
  let dashboard;
  if (auth.isAdmin) {
    dashboard = (

      <Col lg="2" xs='6' className="function-item-wrapper">
      <div className='function-item'>
              <Link className='dropdown-item' to='/dashboard/evenosIndicators'>
          <i className='fa fa-tachometer' aria-hidden='true' />
          Admin Dashboard
        </Link>
        </div>
      </Col>

    );
  }
  let eduCenter;
  if (user?.eduCenterOwner) {
    eduCenter = (

      <Col lg="2" xs='6' className="function-item-wrapper">
      <div className='function-item'>
        <Link className='dropdown-item' to='/educationCenter'>
        <FontAwesomeIcon className="icon" style={{width:'20px', height:'18px', position:'relative', left:'-2px'}} icon={faGraduationCap} aria-hidden='true' />
          <p style={{display:'inline', position:'relative', left:'-5px'}}>Education Center</p>
        </Link>
        </div>
      </Col>

    );
  }

  /* sign up link */
  let signUp;
  if (!auth.isCustomer && !loading) {
    signUp = (
      <Link to='/signup' className='nav-login-link'>
        Sign Up
      </Link>
    );

  }

  /* login link */
  let login;
  if (!auth.isCustomer && !loading) {
    login = (
      <Link to='/login' className='nav-login-link'>
        Login
      </Link>
    );
  }
  let slash;
  if (!auth.isCustomer && !loading) {
    slash = (<p> / </p>);
  }

  /* functionBar */
  let functionBar;
  if (user) {
    functionBar = (
      <Row className='function-bar'>
              <Col lg="2" xs='6' className="function-item-wrapper">
              <div className='function-item'>
          <Link className='dropdown-item' to='/memorandum'>
          <FontAwesomeIcon className="icon" icon={faEnvelope} aria-hidden='true' />
            Memorandum
          </Link>
          </div>
        </Col>
        <Col lg="2" xs='6' className="function-item-wrapper">
              <div className='function-item'>
                        <Link className='dropdown-item' to='/shoppingmall'>
          <FontAwesomeIcon className="icon" icon={faCartPlus} aria-hidden='true' />
            Shopping Mall
          </Link>
          </div>
        </Col>
                <Col lg="2" xs='6' className="function-item-wrapper">
              <div className='function-item'>
                        <Link className='dropdown-item' to='/accountsettings'>
          <FontAwesomeIcon className="icon" icon={faUser} aria-hidden='true' />
            Account Settings
          </Link>
          </div>
        </Col>
                <Col lg="2" xs='6' className="function-item-wrapper">
              <div className='function-item'>
                        <Link className='dropdown-item' to='/shippingaddress'>
          <FontAwesomeIcon className="icon" icon={faAddressCard} aria-hidden='true' />
            Shipping Address
          </Link>

          </div>
        </Col>

        <Col lg="2" xs='6' className="function-item-wrapper">
              <div className='function-item'>
                        <Link className='dropdown-item' to='/orderhistory'>
            <i className='fa fa-folder' aria-hidden='true' />
            Order History
          </Link>

        </div>
        </Col>

        <Col lg="2" xs='6' className="function-item-wrapper">
              <div className='function-item'>
                        <Link className='dropdown-item' to='/familymembers'>
                        <Diversity3Icon className="mui-icon"/>
            Family Members
          </Link>

        </div>
        </Col>
        <Col lg="2" xs='6' className="function-item-wrapper">
              <div className='function-item'>
                        <Link className='dropdown-item' to='/bonusvalue'>
            <FontAwesomeIcon className="icon" icon={faWallet} aria-hidden='true' />
            Bonus Value
          </Link>

          </div>
        </Col>
                <Col lg="2" xs='6' className="function-item-wrapper">
              <div className='function-item'>
                        <Link className='dropdown-item' to='/shoppingcoins'>
            <FontAwesomeIcon className="icon" icon={faCoins} aria-hidden='true' />
            Shopping Coins
          </Link>

          </div>
        </Col>   
        <Col lg="2" xs='6' className="function-item-wrapper">
              <div className='function-item'>
                        <Link className='dropdown-item' to='/gallery'>
            <FontAwesomeIcon className="icon" icon={faImage} aria-hidden='true' />
            Gallery
          </Link>

          </div>
        </Col>     
        {dashboard}
        {eduCenter}
        {(!user.isAdmin && !user?.eduCenterOwner)&&( <Col lg="2" xs='6' className="function-item-wrapper">
        <div className='function-item' onClick={() => logoutUser()}>
          <Link className='dropdown-item' to='/bv'>
            <i className='fa fa-sign-out' aria-hidden='true' />
            Logout
          </Link>
          </div>
        </Col>)}
      </Row>

    )
  }

  let logoLogin;
  if (user) {
    logoLogin = (

      <Row className='logo-login'>
        <Col><a href='/'><img src={require('../assets/pictures/evenos-logo.png')} alt='Evenos Logo'></img></a></Col>
        <Col><a href='/'><p>{user.memberId}</p></a></Col>
      </Row>
    )
  }
  let logoLogout;
  if (!user) {
    logoLogout = (

      <Row className='logo-logout'>
        <Col><a href='/'><img src={require('../assets/pictures/evenos-logo.png')} alt='Evenos Logo'></img></a></Col>
      </Row>
    )
  }


  // this is to target the changes on the toggle menu ("hamburger", "X")
  let showMenu = false
  function toggleMenu() {

    const hamburger = document.querySelector('.burger');
    const nav = document.querySelector('.nav');
    const menuNav = document.querySelector('.menu-nav');

    // console.log(menuNav)
    const navItems = document.querySelectorAll('.item');

    if (!showMenu) {
      hamburger.classList.add('open');
      nav.classList.add('open');
      menuNav.classList.add('open');
      navItems.forEach(item => item.classList.add('open'));

      showMenu=true
    } else {
      hamburger.classList.remove('open');
      nav.classList.remove('open');
      menuNav.classList.remove('open');
      navItems.forEach(item => item.classList.remove('open'));

      showMenu=false
    }
  }


  // to close menu when clicked outside
  const handleClick = (event) => {
    const btn = document.querySelector(".menu-btn");
    const nav = document.querySelector(".nav");

    if (!nav.contains(event.target) && !btn.contains(event.target) && showMenu) {
      // console.log("outside");
      btn.click();
    }
  };
  document.addEventListener('click', handleClick);

  let whiteDiv;
  if (user) {
    whiteDiv = (
      <div className='white-div'></div>
    )
  } 


  return (
    <div>
    <div className="main-navbar">
      <Row>
        <div onClick={toggleMenu} className="menu-btn">
          <span className="burger"></span>
        </div>

        <nav className="nav">
          <ul className="menu-nav">
          <li className="item">
              <a href="/" className="link">
                Home
              </a>
            </li>
            <li className="item">
              <a href="/aboutus" className="link">
                About Us
              </a>
            </li>
            <li className="item">
              <a href="/shoppingmall" className="link">
                Shopping Mall
              </a>
            </li>

            <li className="item">
              <a href="/questions" className="link">
                FAQ
              </a>
            </li>
            <li className="item">
              <a href="/contactus" className="link">
                Contact Us
              </a>
            </li>
            {user && (
              <li className="item">
                <a onClick={logoutUser} href="/" className="link">
                  Logout
                </a>
              </li>
            )}
          </ul>
        </nav>

        {logoLogin}
        {logoLogout}

        <div className='navbar-login'>
          {signUp} {slash} {login}
        </div>
        {/* {userDropDown} */}
        <div className='cart-box'>
          {auth.isCustomer && (

            <Link to='/cart'>
              <i className='fa fa-shopping-cart cart-icon' aria-hidden='true' />
            </Link>
          )}
        </div>
        {auth.isCustomer ? cart.length > 0 && (<Link to='/cart'>
          <div className="counter">{cart.length}</div>
        </Link>) : null}
      </Row>
      {functionBar}

    </div>
    {whiteDiv}
    </div>
  );
}

export default MainNavbar;