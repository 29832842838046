import {
  UPDATE_REGISTRATION_FEE_SUCCESS,
  UPDATE_REGISTRATION_FEE_FAILURE,
  FETCH_REGISTRATION_FEE_STARTED,
  FETCH_REGISTRATION_FEE_SUCCESS,
  FETCH_REGISTRATION_FEE_FAILURE
} from "../actions/types";

const initialState = {
  registrationFee: 0,
  discountFee: 0,
  loading: false,
  error: null,
  success: null
};

const registrationFeeReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_REGISTRATION_FEE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        registrationFee: action.payload.registrationFee,
        discountFee: action.payload.discountFee,
      };
    case UPDATE_REGISTRATION_FEE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        success: null
      };
      case FETCH_REGISTRATION_FEE_STARTED:
        return {
          ...state,
          loading: true
        };
      case FETCH_REGISTRATION_FEE_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
          registrationFee: action.payload.registrationFee,
          discountFee: action.payload.discountFee,
        };
      case FETCH_REGISTRATION_FEE_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload.error,
          success: null
        };
    default:
      return state;
  }
};

export default registrationFeeReducer;