import {
    FETCH_RECRUITMENT_STARTED,
    FETCH_RECRUITMENT_SUCCESS,
    FETCH_RECRUITMENT_FAILURE
  } from "../actions/types";
  
  const initialState = {
    newRetailer: 0,
    allRetailer: 0,
    loading: false,
    error: null,
    success: null
  };
  
  const recruitmentReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_RECRUITMENT_STARTED:
        return {
          ...state,
          loading: true
        };
      case FETCH_RECRUITMENT_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
          newRetailer: action.payload.newRetailer,
          allRetailer: action.payload.allRetailer,
        };
      case FETCH_RECRUITMENT_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload.error,
          success: null
        };
  
      default:
        return state;
    }
  };
  
  export default recruitmentReducer;