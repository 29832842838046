import React, { useEffect, Suspense, lazy  } from "react";
import "font-awesome/css/font-awesome.min.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import "./style/index.scss";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ScrollToTop from "./components/ScrollToTop";
import { loadUser } from "./redux/actions/auth-actions/loadUser";
import { useDispatch, useSelector } from "react-redux";

import MainNavbar from "./components/MainNavbar";
import MainFooter from "./components/MainFooter";

const ShoppingMall = lazy(() => import("./components/home-page/ShoppingMall"));
const Home = lazy(() => import("./components/home-page/Home"));
const AboutUs = lazy(() => import("./components/home-page/AboutUs"));
const Questions = lazy(() => import("./components/home-page/Questions"));
const ContactUs = lazy(() => import("./components/home-page/ContactUs"));
const ContactUsReply = lazy(() => import("./components/home-page/ContactUsReply"));
const Category = lazy(() => import("./components/dashboard/Category"));
const SingleProduct = lazy(() => import("./components/SingleProduct"));
const EditProducts = lazy(() => import("./components/dashboard/EditProducts"));
const AddProductForm = lazy(() => import("./components/dashboard/AddProductForm"));
const SignUpForm = lazy(() => import("./components/login&signup/SignUpForm"));
const LoginForm = lazy(() => import("./components/login&signup/LoginForm"));
const AdminLoginForm = lazy(() => import("./components/login&signup/AdminLoginForm"));
const SignUpPayment = lazy(() => import("./components/login&signup/SignUpPayment"));
const SignUpPaymentSuccess = lazy(() => import("./components/login&signup/SignUpPaymentSuccess"));
const SignUpPaymentFailure = lazy(() => import("./components/login&signup/SignUpPaymentFailure"));
const Members = lazy(() => import("./components/dashboard/Members"));
const AccountControl = lazy(() => import("./components/dashboard/AccountControl"));
const BVAndCoins = lazy(() => import("./components/dashboard/BVAndCoins"));
const Cart = lazy(() => import("./components/cart/Cart"));
const PaymentSuccess = lazy(() => import("./components/cart/PaymentSuccess"));
const PaymentFailure = lazy(() => import("./components/cart/PaymentFailure"));
const CheckOut = lazy(() => import("./components/cart/CheckOut"));
const AccountSettings = lazy(() => import("./components/account-settings/AccountSettings"));
const Gallery = lazy(() => import("./components/account-settings/Gallery"));
const Addresses = lazy(() => import("./components/account-settings/Addresses"));
const OrdersHistory = lazy(() => import("./components/account-settings/OrdersHistory"));
const AddAddressForm = lazy(() => import("./components/account-settings/AddAddressForm"));
const EditAddressForm = lazy(() => import("./components/account-settings/EditAddressForm"));
const EditAccountForm = lazy(() => import("./components/account-settings/EditAccountForm"));
const EditBeneficiaryForm = lazy(() => import("./components/account-settings/EditBeneficiaryForm"));
const ChangePasswordForm = lazy(() => import("./components/account-settings/ChangePasswordForm"));
const OrdersToShip = lazy(() => import("./components/dashboard/OrdersToShip"));
const ShippedOrders = lazy(() => import("./components/dashboard/ShippedOrders"));
const Indicators = lazy(() => import("./components/dashboard/Indicators"));
const Commissions = lazy(() => import("./components/dashboard/Commissions"));
const Memo = lazy(() => import("./components/dashboard/Memo"));
const Memorandum = lazy(() => import("./components/account-settings/Memorandum"));
const EduCenter = lazy(() => import("./components/dashboard/EduCenter"));
const EducationCenter = lazy(() => import("./components/account-settings/EducationCenter"));
const Voucher = lazy(() => import("./components/dashboard/Voucher"));
const UserVoucher = lazy(() => import("./components/account-settings/UserVoucher"));
const UserFamily = lazy(() => import("./components/account-settings/UserFamily"));
const BV = lazy(() => import("./components/account-settings/BV"));
const ShoppingCoins = lazy(() => import("./components/account-settings/ShoppingCoins"));
const TermsAndConditions = lazy(() => import("./components/TermsAndConditions"));
const Page404 = lazy(() => import("./components/404"));
const GeneralSpinner = lazy(() => import("./components/GeneralSpinner"));

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    // load our user everytime we render
    dispatch(loadUser());
  }, [dispatch]);

  const { user, loading, auth } = useSelector(state => state.userrr);

  const enteredPath = window.location.pathname

  // this method to control 404 not found page
  const generateRoute = (path, compt) => {
    let validRoutes = ["/gallery", "/accountsettings", "/accountsettings/edit_account", "/accountsettings/change_password",
    "/accountsettings/edit_beneficiary", "/orderhistory", "/shippingaddress", "/shippingaddress/add_address", 
    "/shippingaddress/edit_address", "/familymembers", "/bonusvalue", "/shoppingcoins",  "/memorandum", 
    "/dashboard/category", "/dashboard/evenosMembers", "/dashboard/evenosIndicators", "/dashboard/memo", 
    "/dashboard/accountControl", "/dashboard/BV&Coins", "/dashboard/addProduct", "/dashboard/editProduct", 
    "/dashboard/ordersToShip", "/dashboard/shippedOrders", "/dashboard/commissions", "/cart", "/checkout", "/paymentsuccess", 
    "/paymentfailure","/dashboard/eduCenter", "/educationCenter", "/dashboard/voucher", "/uservoucher"]

    if (user && auth.isCustomer && !loading) {
      return <Route path={path} component={compt} exact />;
    } else if (loading) {
      return <Route path={path} component={GeneralSpinner} exact />;
    } else if ((!user && !auth.customer && !loading && validRoutes.includes(enteredPath))) {
      return <Route path={path} component={LoginForm} exact />;
    }else if ((!user && !auth.customer && !loading)) {
      return <Route path={path} component={Page404} exact />;
    }
  };

  return (
    <BrowserRouter>
      <ScrollToTop />
      {/* <ToastContainer /> */}

      <div className='App'>
        <MainNavbar />
        <div className='page-body'>
        
        <Suspense fallback={<div></div>}>

        {/* ToastContainer needs to be placed here and import react-toastify css in order for
        this to work in lazy mode, previously it was placed above and no css imported */}
        <ToastContainer />

          <Switch>

            {/* Public Routes */}
            <Route path='/' component={Home} exact />
            <Route path='/signup' component={SignUpForm} />
            <Route path='/signup-payment' component={SignUpPayment} />
            <Route path='/signup-paymentsuccess/:memberId' component={SignUpPaymentSuccess} />
            <Route path='/signup-paymentfailure' component={SignUpPaymentFailure} />
            <Route path='/login' component={LoginForm} />
            <Route path='/adminlogin' component={AdminLoginForm} />
            <Route path='/product/:id' component={SingleProduct} />
            <Route path='/aboutus' component={AboutUs} exact />
            <Route path='/questions' component={Questions} exact />
            <Route path='/contactus' component={ContactUs} exact />
            <Route path='/contactusreply' component={ContactUsReply} exact />
            <Route path='/shoppingmall' component={ShoppingMall} exact />
            <Route path='/shoppingmall/:id' component={ShoppingMall} exact />
            <Route path='/termsandconditions' component={TermsAndConditions} exact />


            {/* Account settings Routes */}
            {generateRoute("/gallery", Gallery)}
            {generateRoute("/accountsettings", AccountSettings)}
            {generateRoute("/accountsettings/edit_account", EditAccountForm)}
            {generateRoute("/accountsettings/change_password", ChangePasswordForm)}
            {generateRoute("/accountsettings/edit_beneficiary", EditBeneficiaryForm)}
            {generateRoute("/orderhistory", OrdersHistory)}
            {generateRoute("/shippingaddress", Addresses)}
            {generateRoute("/shippingaddress/add_address", AddAddressForm)}
            {generateRoute("/shippingaddress/edit_address", EditAddressForm)}
            {generateRoute("/familymembers", UserFamily)}
            {generateRoute("/bonusvalue", BV)}
            {generateRoute("/shoppingcoins", ShoppingCoins)}
            {generateRoute("/memorandum", Memorandum)}
            {generateRoute("/educationCenter", EducationCenter)}
            {generateRoute("/uservoucher", UserVoucher)}

            {/* Admin Dashboard  Routes */}
            {generateRoute("/dashboard/category", Category)}
            {generateRoute("/dashboard/evenosMembers", Members)}
            {generateRoute("/dashboard/evenosIndicators", Indicators)}
            {generateRoute("/dashboard/memo", Memo)}
            {generateRoute("/dashboard/eduCenter", EduCenter)}
            {generateRoute("/dashboard/accountControl", AccountControl)}
            {generateRoute("/dashboard/BV&Coins", BVAndCoins)}
            {generateRoute("/dashboard/addProduct", AddProductForm)}
            {generateRoute("/dashboard/editProduct", EditProducts)}
            {generateRoute("/dashboard/ordersToShip", OrdersToShip)}
            {generateRoute("/dashboard/shippedOrders", ShippedOrders)}
            {generateRoute("/dashboard/commissions", Commissions)}
            {generateRoute("/dashboard/voucher", Voucher)}

            {/* Cart Routes */}
            {generateRoute("/cart", Cart)}
            {generateRoute("/checkout", CheckOut)}
            {generateRoute("/paymentsuccess", PaymentSuccess)}
            {generateRoute("/paymentfailure", PaymentFailure)}

            {/* if no match just render 404 not found page */}
            <Route component={Page404} />
          </Switch>
          </Suspense>

        </div>
        <MainFooter />
      </div>
    </BrowserRouter>
  );
}

export default App;
