// fetch products for shopping mall (all products or category products)

import {
  FETCH_CATEGORY_PRODUCTS_STARTED,
  FETCH_CATEGORY_PRODUCTS_FAILURE,
  FETCH_CATEGORY_PRODUCTS_SUCCESS
} from "../actions/types";

const initialState = {
  category:"",
  products: [],
  // pagesCount: null,
  loading: false,
  error: null,
  success: null
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
      case FETCH_CATEGORY_PRODUCTS_STARTED:
        return {
          ...state,
          loading: true
        };
      case FETCH_CATEGORY_PRODUCTS_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
          category:action.payload.category,
          products: action.payload.products,
        };
      case FETCH_CATEGORY_PRODUCTS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload.error
        };

    default:
      return state;
  }
};

export default productReducer;
