import {
  FETCH_BV_WITHDRAWAL_STARTED,
  FETCH_BV_WITHDRAWAL_SUCCESS,
  FETCH_BV_WITHDRAWAL_FAILURE,
  CHANGE_DONE_FOR_BV_SUCCESS,
  CHANGE_DONE_FOR_BV_FAILURE,
  SEARCH_BV_WITHDRAWAL_STARTED,
  SEARCH_BV_WITHDRAWAL_SUCCESS,
  SEARCH_BV_WITHDRAWAL_FAILURE
} from "../actions/types";

const initialState = {
  bvWithdrawal: [],
  pagesCount: null,
  loading: false,
  error: null,
  success: null
};

const bvWithdrawalReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BV_WITHDRAWAL_STARTED:
      return {
        ...state,
        loading: true
      };
    case FETCH_BV_WITHDRAWAL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        bvWithdrawal: action.payload.bvWithdrawal,
        pagesCount: action.payload.pagesCount
      };
    case FETCH_BV_WITHDRAWAL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        success: null
      };
    case CHANGE_DONE_FOR_BV_SUCCESS:
      return {
        ...state,
        loading: false,
        bvWithdrawal: state.bvWithdrawal.map(bv => {
          if (bv._id === action.payload.bv._id) {
            return action.payload.bv;
          }
          return bv;
        }),
        success: action.payload.message,
        error: null
      };
    case CHANGE_DONE_FOR_BV_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        success: null
      };
    case SEARCH_BV_WITHDRAWAL_STARTED:
      return {
        ...state,
        loading: true
      };
    case SEARCH_BV_WITHDRAWAL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        bvWithdrawal: action.payload.bvWithdrawal,
        pagesCount: action.payload.pagesCount
      };
    case SEARCH_BV_WITHDRAWAL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        success: null
      };
    default:
      return state;


  }

};

export default bvWithdrawalReducer;