import {
    FETCH_RATING_INFO_STARTED,
    FETCH_RATING_INFO_SUCCESS,
    FETCH_RATING_INFO_FAILURE,
    SUBMIT_RATING_SUCCESS,
    SUBMIT_RATING_FAILURE,
} from '../actions/types';

const initialState = {
    ratingInfo:{},
    loading: false,
    error: null
}

const ratingReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_RATING_INFO_STARTED:
            return {
                ...state,
                loading: true
            }
        case FETCH_RATING_INFO_SUCCESS:
            return {
                ...state,
                ratingInfo: action.payload.ratingInfo,
                loading: false,
                error: null
            }
        case FETCH_RATING_INFO_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        case SUBMIT_RATING_SUCCESS:
            return {
                ...state,
                ratingInfo: action.payload.ratingInfo,
                loading: false,
                error: null
            }
        case SUBMIT_RATING_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
                }
        default:
            return state;
    }
}

export default ratingReducer;