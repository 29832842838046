import {
    FETCH_BV_STARTED,
    FETCH_BV_SUCCESS,
    FETCH_BV_FAILURE
  } from "../actions/types";
  
  const initialState = {
    totalBV: null,
    earnedBVByMonth:null,
    pagesCount: null,
    allBV: [],
    loading: false,
    error: null,
    success: null
  };
  
  const bvReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_BV_STARTED:
        return {
          ...state,
          loading: true
        };
      case FETCH_BV_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
          allBV: action.payload.allBV,
          totalBV: action.payload.totalBV,
          earnedBVByMonth: action.payload.earnedBVByMonth,
          pagesCount: action.payload.pagesCount


        };
      case FETCH_BV_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload.error,
          success: null
        };
  
      default:
        return state;
    }
  };
  
  export default bvReducer;