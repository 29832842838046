import {
    FETCH_USER_FAMILY_STARTED,
    FETCH_USER_FAMILY_SUCCESS,
    FETCH_USER_FAMILY_FAILURE
  } from "../actions/types";
  
  const initialState = {
    userfamily:[],
    loading: false,
    error: null,
    success: null
  };
  
  const userFamilyReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_USER_FAMILY_STARTED:
        return {
          ...state,
          loading: true
        };
      case FETCH_USER_FAMILY_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
          userfamily: action.payload.userfamily,
        };
      case FETCH_USER_FAMILY_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload.error,
          success: null
        };
  
      default:
        return state;
    }
  };
  
  export default userFamilyReducer;