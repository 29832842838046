import {
    FETCH_VOUCHER_STARTED,
    FETCH_VOUCHER_FAILURE,
    FETCH_VOUCHER_SUCCESS,
    CREATE_VOUCHER_SUCCESS,
    CREATE_VOUCHER_FAILURE,
    DELETE_VOUCHER_SUCCESS,
    DELETE_VOUCHER_FAILURE,
  } from "../actions/types";
  
  const initialState = {
    voucher: [],
    loading: false,
    error: null,
    success: null
  };
  
  const voucherReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_VOUCHER_STARTED:
            return {
                ...state,
                loading: true
            };
        case FETCH_VOUCHER_SUCCESS:
            return {
                ...state,
                voucher: action.payload.voucher,
                loading: false,
                error: null
            };
        case FETCH_VOUCHER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                success: null
            };
        case CREATE_VOUCHER_SUCCESS:
            return {
                ...state,
                voucher: [action.payload.addedVoucher, ...state.voucher],
                error: null,
                success: action.payload.successMessage
            };
        case CREATE_VOUCHER_FAILURE:
            return {
                ...state,
                error: action.payload.error,
                success: null
            };
        case DELETE_VOUCHER_SUCCESS:
            return {
                ...state,
                voucher: state.voucher.filter(
                    item => item._id !== action.payload.id
                ),
                error: null,
                success: action.payload.successMessage
            };
        case DELETE_VOUCHER_FAILURE:
            return {
                ...state,
                error: action.payload.error,
                success: null
            };
  
        default:
            return state;
    }
  };
  
  export default voucherReducer;
  