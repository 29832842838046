// Fetch categories cases
export const FETCH_CATEGORIES_STARTED = "FETCH_CATEGORIES_STARTED";
export const FETCH_CATEGORIES_FAILURE = "FETCH_CATEGORIES_FAILURE";
export const FETCH_CATEGORIES_SUCCESS = "FETCH_CATEGORIES_SUCCESS";

// Fetch each category's products cases
export const FETCH_CATEGORY_PRODUCTS_STARTED = "FETCH_CATEGORY_PRODUCTS_STARTED";
export const FETCH_CATEGORY_PRODUCTS_FAILURE = "FETCH_CATEGORY_PRODUCTS_FAILURE";
export const FETCH_CATEGORY_PRODUCTS_SUCCESS = "FETCH_CATEGORY_PRODUCTS_SUCCESS";

// Fetch all products
export const FETCH_PRODUCTS_STARTED = "FETCH_PRODUCTS_STARTED";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCTS_FAILURE = "FETCH_PRODUCTS_FAILURE";

// Fetch all products
export const FETCH_USER_PRODUCTS_STARTED = "FETCH_USER_PRODUCTS_STARTED";
export const FETCH_USER_PRODUCTS_SUCCESS = "FETCH_USER_PRODUCTS_SUCCESS";
export const FETCH_USER_PRODUCTS_FAILURE = "FETCH_USER_PRODUCTS_FAILURE";

// Fetch single product
export const FETCH_SINGLE_PRODUCT_STARTED = "FETCH_SINGLE_PRODUCT_STARTED";
export const FETCH_SINGLE_PRODUCT_SUCCESS = "FETCH_SINGLE_PRODUCT_SUCCESS";
export const FETCH_SINGLE_PRODUCT_FAILURE = "FETCH_SINGLE_PRODUCT_FAILURE";

// Add new Category
export const ADD_CATEGORY_SUCCESS = "ADD_CATEGORY_SUCCESS";
export const ADD_CATEGORY_FAILURE = "ADD_CATEGORY_FAILURE";

// Add new Product
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_FAILURE = "ADD_PRODUCT_FAILURE";

// Delete category
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_FAILURE = "DELETE_CATEGORY_FAILURE";

// Update Category
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_FAILURE = "UPDATE_CATEGORY_FAILURE";

// Delete product
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAILURE = "DELETE_PRODUCT_FAILURE";

// Update Product
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAILURE = "UPDATE_PRODUCT_FAILURE";

// users options
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const REGISTER_USER_DRAFT_SUCCESS = "REGISTER_USER_DRAFT_SUCCESS";
export const REGISTER_USER_DRAFT_FAILURE = "REGISTER_USER_DRAFT_FAILURE";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILURE = "LOGIN_USER_FAILURE";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";
export const UPDATE_BENEFICIARY_SUCCESS = "UPDATE_BENEFICIARY_SUCCESS";
export const UPDATE_BENEFICIARY_FAILURE = "UPDATE_BENEFICIARY_FAILURE";

// fetch cart products
export const FETCH_CART_STARTED = "FETCH_CART_STARTED";
export const FETCH_CART_SUCCESS = "FETCH_CART_SUCCESS";
export const FETCH_CART_FAILURE = "FETCH_CART_FAILURE";

// add product to cart
export const ADD_TO_CART_SUCCESS = "ADD_TO_CART_SUCCESS";
export const ADD_TO_CART_FAILURE = "ADD_TO_CART_FAILURE";

// change quantity from cart
export const CHANGE_CART_QUANTITY_STARTED = "CHANGE_CART_QUANTITY_STARTED";
export const CHANGE_CART_QUANTITY_SUCCESS = "CHANGE_CART_QUANTITY_SUCCESS";
export const CHANGE_CART_QUANTITY_FAILURE = "CHANGE_CART_QUANTITY_FAILURE";

// delete product from cart
export const DELETE_FROM_CART_STARTED = "DELETE_FROM_CART_STARTED";
export const DELETE_FROM_CART_SUCCESS = "DELETE_FROM_CART_SUCCESS";
export const DELETE_FROM_CART_FAILURE = "DELETE_FROM_CART_FAILURE";

// check cart item
export const CHECK_CART_ITEM_STARTED = "CHECK_CART_ITEM_STARTED";
export const CHECK_CART_ITEM_SUCCESS = "CHECK_CART_ITEM_SUCCESS";
export const CHECK_CART_ITEM_FAILURE = "CHECK_CART_ITEM_FAILURE";

// fetch All user Addresses
export const FETCH_ADDRESSES_STARTED = "FETCH_ADDRESSES_STARTED";
export const FETCH_ADDRESSES_SUCCESS = "FETCH_ADDRESSES_SUCCESS";
export const FETCH_ADDRESSES_FAILURE = "FETCH_ADDRESSES_FAILURE";

// add a new address
export const ADD_ADDRESS_SUCCESS = "ADD_ADDRESS_SUCCESS";
export const ADD_ADDRESS_FAILURE = "ADD_ADDRESS_FAILURE";

// delete an address
export const DELETE_ADDRESS_SUCCESS = "DELETE_ADDRESS_SUCCESS";
export const DELETE_ADDRESS_FAILURE = "DELETE_ADDRESS_FAILURE";

// update an address
export const EDIT_ADDRESS_SUCCESS = "EDIT_ADDRESS_SUCCESS";
export const EDIT_ADDRESS_FAILURE = "EDIT_ADDRESS_FAILURE";

// fetch WISHLIST products
export const FETCH_WISHLIST_STARTED = "FETCH_WISHLIST_STARTED";
export const FETCH_WISHLIST_SUCCESS = "FETCH_WISHLIST_SUCCESS";
export const FETCH_WISHLIST_FAILURE = "FETCH_WISHLIST_FAILURE";

// add product to WISHLIST
export const ADD_TO_WISHLIST_SUCCESS = "ADD_TO_WISHLIST_SUCCESS";
export const ADD_TO_WISHLIST_FAILURE = "ADD_TO_WISHLIST_FAILURE";

// delete product from WISHLIST
export const DELETE_FROM_WISHLIST_SUCCESS = "DELETE_FROM_WISHLIST_SUCCESS";
export const DELETE_FROM_WISHLIST_FAILURE = "DELETE_FROM_WISHLIST_FAILURE";

// fetch All user orders history
export const FETCH_HISTORY_STARTED = "FETCH_HISTORY_STARTED";
export const FETCH_HISTORY_SUCCESS = "FETCH_HISTORY_SUCCESS";
export const FETCH_HISTORY_FAILURE = "FETCH_HISTORY_FAILURE";

// fetch All seller's orders to ship
export const FETCH_ORDERS_TO_SHIP_STARTED = "FETCH_ORDERS_TO_SHIP_STARTED";
export const FETCH_ORDERS_TO_SHIP_SUCCESS = "FETCH_ORDERS_TO_SHIP_SUCCESS";
export const FETCH_ORDERS_TO_SHIP_FAILURE = "FETCH_ORDERS_TO_SHIP_FAILURE";

// fetch All seller's shipped orders
export const FETCH_SHIPPED_ORDERS_STARTED = "FETCH_SHIPPED_ORDERS_STARTED";
export const FETCH_SHIPPED_ORDERS_SUCCESS = "FETCH_SHIPPED_ORDERS_SUCCESS";
export const FETCH_SHIPPED_ORDERS_FAILURE = "FETCH_SHIPPED_ORDERS_FAILURE";

// Mark order as shipped
export const ORDER_SHIPPED_SUCCESS = "ORDER_SHIPPED_SUCCESS";
export const ORDER_SHIPPED_FAILURE = "ORDER_SHIPPED_SUCCESS";

// Choose order address
export const CHOOSE_ORDER_ADDRESS_STARTED = "CHOOSE_ORDER_ADDRESS_STARTED";
export const CHOOSE_ORDER_ADDRESS_SUCCESS = "CHOOSE_ORDER_ADDRESS_SUCCESS";
export const CHOOSE_ORDER_ADDRESS_FAILURE = "CHOOSE_ORDER_ADDRESS_SUCCESS";

// users options
export const FETCH_ALL_USERS_STARTED = "FETCH_ALL_USERS_STARTED";
export const FETCH_ALL_USERS_SUCCESS = "FETCH_ALL_USERS_SUCCESS";
export const FETCH_ALL_USERS_FAILURE = "FETCH_ALL_USERS_FAILURE";

// change user permissions to admin
// export const ADMIN_PERMISSION_SUCCESS = "ADMIN_PERMISSION_SUCCESS";
// export const ADMIN_PERMISSION_FAILURE = "ADMIN_PERMISSION_FAILURE";

// restrict user from all permissions
export const STRICT_USER_SUCCESS = "STRICT_USER_SUCCESS";
export const STRICT_USER_FAILURE = "STRICT_USER_FAILURE";

// Fetch indicators total sales
export const FETCH_TOTAL_SALES_STARTED = "FETCH_TOTAL_SALES_STARTED";
export const FETCH_TOTAL_SALES_SUCCESS = "FETCH_TOTAL_SALES_SUCCESS";
export const FETCH_TOTAL_SALES_FAILURE = "FETCH_TOTAL_SALES_FAILURE";

// Fetch user family
export const FETCH_USER_FAMILY_STARTED = "FETCH_USER_FAMILY_STARTED";
export const FETCH_USER_FAMILY_SUCCESS = "FETCH_USER_FAMILY_SUCCESS";
export const FETCH_USER_FAMILY_FAILURE = "FETCH_USER_FAMILY_FAILURE";

// Fetch BV
export const FETCH_BV_STARTED = "FETCH_BV_STARTED";
export const FETCH_BV_SUCCESS = "FETCH_BV_SUCCESS";
export const FETCH_BV_FAILURE = "FETCH_BV_FAILURE";

// Check referral name from sign up
export const CHECK_PARENT_NAME_STARTED = "CHECK_PARENT_NAME_STARTED";
export const CHECK_PARENT_NAME_SUCCESS = "CHECK_PARENT_NAME_SUCCESS";
export const CHECK_PARENT_NAME_FAILURE = "CHECK_PARENT_NAME_FAILURE";

// Fetch BV withdrawal
export const FETCH_BV_WITHDRAWAL_STARTED = "FETCH_BV_WITHDRAWAL_STARTED";
export const FETCH_BV_WITHDRAWAL_SUCCESS = "FETCH_BV_WITHDRAWAL_SUCCESS";
export const FETCH_BV_WITHDRAWAL_FAILURE = "FETCH_BV_WITHDRAWAL_FAILURE";

// Fetch memo
export const FETCH_MEMO_STARTED = "FETCH_MEMO_STARTED";
export const FETCH_MEMO_SUCCESS = "FETCH_MEMO_SUCCESS";
export const FETCH_MEMO_FAILURE = "FETCH_MEMO_FAILURE";

// Create new memo
export const CREATE_MEMO_SUCCESS = "CREATE_MEMO_SUCCESS";
export const CREATE_MEMO_FAILURE = "CREATE_MEMO_FAILURE";

// Update memo
export const UPDATE_MEMO_SUCCESS = "UPDATE_MEMO_SUCCESS";
export const UPDATE_MEMO_FAILURE = "UPDATE_MEMO_FAILURE";

// Delete memo
export const DELETE_MEMO_SUCCESS = "DELETE_MEMO_SUCCESS";
export const DELETE_MEMO_FAILURE = "DELETE_MEMO_FAILURE";

// Fetch Shopping coins
export const FETCH_SHOPPING_COINS_STARTED = "FETCH_SHOPPING_COINS_STARTED";
export const FETCH_SHOPPING_COINS_SUCCESS = "FETCH_SHOPPING_COINS_SUCCESS";
export const FETCH_SHOPPING_COINS_FAILURE = "FETCH_SHOPPING_COINS_FAILURE";

// Fetch recruitment
export const FETCH_RECRUITMENT_STARTED = "FETCH_RECRUITMENT_STARTED";
export const FETCH_RECRUITMENT_SUCCESS = "FETCH_RECRUITMENT_SUCCESS";
export const FETCH_RECRUITMENT_FAILURE = "FETCH_RECRUITMENT_FAILURE";

// Search members in dashboard all members
export const SEARCH_MEMBER_STARTED = "SEARCH_MEMBER_STARTED";
export const SEARCH_MEMBER_SUCCESS = "SEARCH_MEMBER_SUCCESS";
export const SEARCH_MEMBER_FAILURE = "SEARCH_MEMBER_FAILURE";

// Search order in dashboard orders to ship
export const SEARCH_ORDERS_TO_SHIP_STARTED = "SEARCH_ORDERS_TO_SHIP_STARTED";
export const SEARCH_ORDERS_TO_SHIP_SUCCESS = "SEARCH_ORDERS_TO_SHIP_SUCCESS";
export const SEARCH_ORDERS_TO_SHIP_FAILURE = "SEARCH_ORDERS_TO_SHIP_FAILURE";

// Search order in dashboard shipped orders
export const SEARCH_SHIPPED_ORDERS_STARTED = "SEARCH_SHIPPED_ORDERS_STARTED";
export const SEARCH_SHIPPED_ORDERS_SUCCESS = "SEARCH_SHIPPED_ORDERS_SUCCESS";
export const SEARCH_SHIPPED_ORDERS_FAILURE = "SEARCH_SHIPPED_ORDERS_FAILURE";

// Update discount bulk for products (price and BV)
export const EDIT_DISCOUNT_BULK_SUCCESS = "EDIT_DISCOUNT_BULK_SUCCESS";
export const EDIT_DISCOUNT_BULK_FAILURE = "EDIT_DISCOUNT_BULK_FAILURE";

// change to the opposite of 'done' in evenos members
export const CHANGE_DONE_FOR_MEMBER_SUCCESS = "CHANGE_DONE_FOR_MEMBER_SUCCESS";
export const CHANGE_DONE_FOR_MEMBER_FAILURE = "CHANGE_DONE_FOR_MEMBER_FAILURE";

// update registration Fee and discount by admin
export const UPDATE_REGISTRATION_FEE_SUCCESS = "UPDATE_REGISTRATION_FEE_SUCCESS";
export const UPDATE_REGISTRATION_FEE_FAILURE = "UPDATE_REGISTRATION_FEE_FAILURE";

// fetch registration Fee and discount
export const FETCH_REGISTRATION_FEE_STARTED = "FETCH_REGISTRATION_FEE_STARTED";
export const FETCH_REGISTRATION_FEE_SUCCESS = "FETCH_REGISTRATION_FEE_SUCCESS";
export const FETCH_REGISTRATION_FEE_FAILURE = "FETCH_REGISTRATION_FEE_FAILURE";

// find nearest BP for each member in account settings
export const FIND_NEAREST_BP_STARTED = "FIND_NEAREST_BP_STARTED";
export const FIND_NEAREST_BP_SUCCESS = "FIND_NEAREST_BP_SUCCESS";
export const FIND_NEAREST_BP_FAILURE = "FIND_NEAREST_BP_FAILURE";

// Add product rating
export const SUBMIT_RATING_SUCCESS = "SUBMIT_RATING_SUCCESS";
export const SUBMIT_RATING_FAILURE = "SUBMIT_RATING_FAILURE";

// fetch rating info of a product
export const FETCH_RATING_INFO_STARTED = "FETCH_RATING_INFO_STARTED";
export const FETCH_RATING_INFO_SUCCESS = "FETCH_RATING_INFO_SUCCESS";
export const FETCH_RATING_INFO_FAILURE = "FETCH_RATING_INFO_FAILURE";

// Fetch eduCenter
export const FETCH_EDU_CENTER_STARTED = "FETCH_EDU_CENTER_STARTED";
export const FETCH_EDU_CENTER_SUCCESS = "FETCH_EDU_CENTER_SUCCESS";
export const FETCH_EDU_CENTER_FAILURE = "FETCH_EDU_CENTER_FAILURE";

// Create new eduCenter
export const CREATE_EDU_CENTER_SUCCESS = "CREATE_EDU_CENTER_SUCCESS";
export const CREATE_EDU_CENTER_FAILURE = "CREATE_EDU_CENTER_FAILURE";

// Update eduCenter
export const UPDATE_EDU_CENTER_SUCCESS = "UPDATE_EDU_CENTER_SUCCESS";
export const UPDATE_EDU_CENTER_FAILURE = "UPDATE_EDU_CENTER_FAILURE";

// Delete eduCenter
export const DELETE_EDU_CENTER_SUCCESS = "DELETE_EDU_CENTER_SUCCESS";
export const DELETE_EDU_CENTER_FAILURE = "DELETE_EDU_CENTER_FAILURE";

// Fetch vouchers
export const FETCH_VOUCHER_STARTED = "FETCH_VOUCHER_STARTED";
export const FETCH_VOUCHER_SUCCESS = "FETCH_VOUCHER_SUCCESS";
export const FETCH_VOUCHER_FAILURE = "FETCH_VOUCHER_FAILURE";

// Create new voucher
export const CREATE_VOUCHER_SUCCESS = "CREATE_VOUCHER_SUCCESS";
export const CREATE_VOUCHER_FAILURE = "CREATE_VOUCHER_FAILURE";

// Delete voucher
export const DELETE_VOUCHER_SUCCESS = "DELETE_VOUCHER_SUCCESS";
export const DELETE_VOUCHER_FAILURE = "DELETE_VOUCHER_FAILURE";

// Fetch user vouchers
export const FETCH_USER_VOUCHER_STARTED = "FETCH_USER_VOUCHER_STARTED";
export const FETCH_USER_VOUCHER_SUCCESS = "FETCH_USER_VOUCHER_SUCCESS";
export const FETCH_USER_VOUCHER_FAILURE = "FETCH_USER_VOUCHER_FAILURE";

// Claim user voucher
export const CLAIM_USER_VOUCHER_STARTED = "CLAIM_USER_VOUCHER_STARTED";
export const CLAIM_USER_VOUCHER_SUCCESS = "CLAIM_USER_VOUCHER_SUCCESS";
export const CLAIM_USER_VOUCHER_FAILURE = "CLAIM_USER_VOUCHER_FAILURE";

// Redeem user voucher in cart and cartReducer
export const REDEEM_USER_VOUCHER_STARTED = "REDEEM_USER_VOUCHER_STARTED";
export const REDEEM_USER_VOUCHER_SUCCESS = "REDEEM_USER_VOUCHER_SUCCESS";
export const REDEEM_USER_VOUCHER_FAILURE = "REDEEM_USER_VOUCHER_FAILURE";

// change to the opposite of 'done' in bv withdrawal
export const CHANGE_DONE_FOR_BV_SUCCESS = "CHANGE_DONE_FOR_BV_SUCCESS";
export const CHANGE_DONE_FOR_BV_FAILURE = "CHANGE_DONE_FOR_BV_FAILURE";

// Search bv withdrawal in dashboard BV & Coins
export const SEARCH_BV_WITHDRAWAL_STARTED = "SEARCH_BV_WITHDRAWAL_STARTED";
export const SEARCH_BV_WITHDRAWAL_SUCCESS = "SEARCH_BV_WITHDRAWAL_SUCCESS";
export const SEARCH_BV_WITHDRAWAL_FAILURE = "SEARCH_BV_WITHDRAWAL_FAILURE";