import {
  FETCH_MEMO_STARTED,
  FETCH_MEMO_FAILURE,
  FETCH_MEMO_SUCCESS,
  CREATE_MEMO_SUCCESS,
  CREATE_MEMO_FAILURE,
  DELETE_MEMO_SUCCESS,
  DELETE_MEMO_FAILURE,
  UPDATE_MEMO_SUCCESS,
  UPDATE_MEMO_FAILURE
} from "../actions/types";

const initialState = {
  memo: [],
  loading: false,
  error: null,
  success: null
};

const memoReducer = (state = initialState, action) => {
  switch (action.type) {
      case FETCH_MEMO_STARTED:
          return {
              ...state,
              loading: true
          };
      case FETCH_MEMO_SUCCESS:
          return {
              ...state,
              memo: action.payload.memo,
              loading: false,
              error: null
          };
      case FETCH_MEMO_FAILURE:
          return {
              ...state,
              loading: false,
              error: action.payload.error,
              success: null
          };
      case CREATE_MEMO_SUCCESS:
          return {
              ...state,
              memo: [action.payload.addedMemo, ...state.memo],
              error: null,
              success: action.payload.successMessage
          };
      case CREATE_MEMO_FAILURE:
          return {
              ...state,
              error: action.payload.error,
              success: null
          };
      case DELETE_MEMO_SUCCESS:
          return {
              ...state,
              memo: state.memo.filter(
                  item => item._id !== action.payload.id
              ),
              error: null,
              success: action.payload.successMessage
          };
      case DELETE_MEMO_FAILURE:
          return {
              ...state,
              error: action.payload.error,
              success: null
          };
      case UPDATE_MEMO_SUCCESS:
          return {
              ...state,
              memo: state.memo.map(item => {
                  if (item._id === action.payload.id) {
                      return action.payload.updatedMemo;
                  }
                  return item;
              }),
              error: null,
              success: action.payload.successMessage
          };
      case UPDATE_MEMO_FAILURE:
          return {
              ...state,
              error: action.payload.error,
              success: null
          };

      default:
          return state;
  }
};

export default memoReducer;
