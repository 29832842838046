import {
    FETCH_USER_VOUCHER_STARTED,
    FETCH_USER_VOUCHER_FAILURE,
    FETCH_USER_VOUCHER_SUCCESS,
    CLAIM_USER_VOUCHER_STARTED,
    CLAIM_USER_VOUCHER_SUCCESS,
    CLAIM_USER_VOUCHER_FAILURE,
  } from "../actions/types";
  
  const initialState = {
    userVoucher: [],
    loading: false,
    error: null,
    success: null
  };
  
  const userVoucherReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_USER_VOUCHER_STARTED:
            return {
                ...state,
                loading: true
            };
        case FETCH_USER_VOUCHER_SUCCESS:
            return {
                ...state,
                userVoucher: action.payload.userVoucher,
                loading: false,
                error: null
            };
        case FETCH_USER_VOUCHER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                success: null
            };
        case CLAIM_USER_VOUCHER_STARTED:
            return {
                ...state,
                loading: true
            };
        case CLAIM_USER_VOUCHER_SUCCESS:
            return {
                ...state,
                loading: false,
                userVoucher: action.payload.userVoucher,
                error: null,
                success: action.payload.successMessage
            };
        case CLAIM_USER_VOUCHER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                success: null
            };

  
        default:
            return state;
    }
  };
  
  export default userVoucherReducer;
  