import {
  REGISTER_USER_DRAFT_SUCCESS,

} from "../actions/types";

let initialState = {
  userDraft: "",
  loading: null,
  success: null,
  error: null
};

const userDraftReducer = (state = initialState, action) => {
  switch (action.type) {
    
    case REGISTER_USER_DRAFT_SUCCESS:
      localStorage.setItem("token", action.payload.token);
      return {
        ...state,
        userDraft: action.payload.userDraft,
        loading: false,
        error: null,
        success: action.payload.successMessage
      };
   
    default:
      return state;
  }
};

export default userDraftReducer;
