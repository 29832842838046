import {
    CHECK_PARENT_NAME_STARTED,
    CHECK_PARENT_NAME_SUCCESS,
    CHECK_PARENT_NAME_FAILURE
  } from "../actions/types";
  
  const initialState = {
    parentName: null,
    loading: false,
    error: null,
    success: null
  };
  
  const checkParentNameReducer = (state = initialState, action) => {
    switch (action.type) {
      case CHECK_PARENT_NAME_STARTED:
        return {
          ...state,
          loading: true
        };
      case CHECK_PARENT_NAME_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
          parentName: action.payload.parentName,
        };
      case CHECK_PARENT_NAME_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload.error,
          success: null,
          parentName: null
        };
  
      default:
        return state;
    }
  };
  
  export default checkParentNameReducer;