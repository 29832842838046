import {
  FIND_NEAREST_BP_STARTED,
  FIND_NEAREST_BP_SUCCESS,
  FIND_NEAREST_BP_FAILURE,
} from "../actions/types";

const initialState = {
  // if no bp found, then will return nothing and account setting page will get this 
  // default empty value and avoid getting error
  nearestBP: { memberId: "", icName: "", hpNum: "" },
  loading: false,
  error: null,
  success: null,
};

const nearestBPReducer = (state = initialState, action) => {
  switch (action.type) {
    case FIND_NEAREST_BP_STARTED:
      return {
        ...state,
        loading: true,
      };
    case FIND_NEAREST_BP_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        nearestBP: action.payload.nearestBP,
      };
    case FIND_NEAREST_BP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        success: null,
      };

    default:
      return state;
  }
};

export default nearestBPReducer;
