import {
    FETCH_SINGLE_PRODUCT_STARTED,
    FETCH_SINGLE_PRODUCT_SUCCESS,
    FETCH_SINGLE_PRODUCT_FAILURE
} from '../actions/types';

const initialState = {
    product: {},
    descriptionEN:[],
    descriptionCN:[],
    loading: false,
    error: null
}

const singleProductReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SINGLE_PRODUCT_STARTED:
            return {
                ...state,
                loading: true
            }
        case FETCH_SINGLE_PRODUCT_SUCCESS:
            let descriptionEN = action.payload.product.descriptionEN.split(/\r?\n/)
            let descriptionCN = action.payload.product.descriptionCN.split(/\r?\n/)

            return {
                ...state,
                product: action.payload.product,
                descriptionEN:descriptionEN,
                descriptionCN:descriptionCN,
                loading: false,
                error: null
            }
        case FETCH_SINGLE_PRODUCT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        default:
            return state;
    }
}

export default singleProductReducer;