/**
 * The tokenConfig function is a utility function that generates a configuration object for an HTTP request.
 * It is used to include headers and optional parameters in the request configuration.
 *
 * Finally, the config object is returned, which can be used as the configuration for an
 * HTTP request (e.g., with Axios) to include the necessary headers and parameters,
 * including the token if available.
 */
export const tokenConfig = (getState = null, optionalQuery = null) => {
  /**
  * When the getState and optionalQuery parameters are set to null in the function signature 
  * ((getState = null, optionalQuery = null)), it means that these parameters are optional.
  * 
  * By default, if you invoke the tokenConfig function without providing values for these parameters, 
  * they will be assigned the value null. This allows you to call the tokenConfig function without passing 
  * any arguments for getState or optionalQuery.
  */
  const token = getState ? getState().userrr.token : null;

  const config = {
    headers: {
      "Content-type": "application/json",
    },
    // don't know why i put query here it doesn't work, but chatgpt said can
    params: {},
  };

  // If token, add to headers
  if (token) {
    config.headers["x-auth-token"] = token;
  }

  if ( optionalQuery) {
    config.params = optionalQuery;
  }
  // console.log(config)
  // can see how 'config' looks like, the "x-auth-token" added in the headers below "Content-type"

  return config;
};


// 'dispatch' and 'getState' (above axios) are the methods from redux