// -------------- original code, but have error on window.__REDUX_DEVTOOLS_EXTENSION__ -----------------------
// -------------- without 'window.__REDUX_DEVTOOLS_EXTENSION', Chrome Redux DevTools won't work --------------

// import { createStore, compose, applyMiddleware } from "redux";
// import thunk from "redux-thunk";
// import rootReducer from "./reducers/rootReducer";

// const store = createStore(
//   rootReducer,
//   compose(
//     applyMiddleware(thunk)
//     // for redux dev tools
//     // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
//   )
// );

// export default store;


// ------------- code written by chatGPT and it works fine in Chrome Redux DevTools --------------------------

import { createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers/rootReducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
