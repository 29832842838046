import { combineReducers } from "redux";
import productReducer from "./productsReducer";
import userProductsReducer from "./userProductsReducer";
import categoryReducer from "./categoryReducer";
import singleProductReducer from "./singleProductReducer";
import usersReducer from "./usersReducer";
import cartReducer from "./cartReducer";
import addressReducer from "./addressReducer";
import ordersHistoryReducer from "./ordersHistoryReducer";
import ordersToShip from "./ordersToShip";
import permissionsReducer from "./permissionsReducer";
import indicatorsReducer from "./indicatorsReducer";
import userFamilyReducer from "./userFamilyReducer";
import bvReducer from "./bvReducer";
import checkParentNameReducer from "./checkParentNameReducer";
import bvWithdrawalReducer from "./bvWithdrawalReducer";
import shoppingCoinsReducer from "./shoppingCoinsReducer";
import userDraftReducer from "./userDraftReducer";
import memoReducer from "./memoReducer";
import eduCenterReducer from "./eduCenterReducer";
import recruitmentReducer from "./recruitmentReducer";
import registrationFeeReducer from "./registrationFeeReducer";
import nearestBPReducer from "./nearestBPReducer";
import ratingReducer from "./ratingReducer";
import voucherReducer from "./voucherReducer";
import userVoucherReducer from "./userVoucherReducer";

const rootReducer = combineReducers({
  productsss: productReducer,
  userProductsss: userProductsReducer,
  categoriesss: categoryReducer,
  singleProducttt: singleProductReducer,
  userrr: usersReducer,
  carttt: cartReducer,
  addresss: addressReducer,
  historyyy: ordersHistoryReducer,
  ordersToShippp: ordersToShip,
  permissionsss: permissionsReducer,
  indicatorsss: indicatorsReducer,
  userFamilyyy: userFamilyReducer,
  bvvv: bvReducer,
  shoppingCoinsss: shoppingCoinsReducer,
  checkParentNameee: checkParentNameReducer,
  bvWithdrawalll: bvWithdrawalReducer,
  memooo: memoReducer,
  eduCenterrr: eduCenterReducer,
  recruitmenttt: recruitmentReducer,
  userDrafttt: userDraftReducer,
  registrationFeeee: registrationFeeReducer,
  nearestBppp: nearestBPReducer,
  ratinggg: ratingReducer,
  voucherrr: voucherReducer,
  userVoucherrr: userVoucherReducer,
});

export default rootReducer;
