import {
  FETCH_EDU_CENTER_STARTED,
  FETCH_EDU_CENTER_FAILURE,
  FETCH_EDU_CENTER_SUCCESS,
  CREATE_EDU_CENTER_SUCCESS,
  CREATE_EDU_CENTER_FAILURE,
  DELETE_EDU_CENTER_SUCCESS,
  DELETE_EDU_CENTER_FAILURE,
  UPDATE_EDU_CENTER_SUCCESS,
  UPDATE_EDU_CENTER_FAILURE
} from "../actions/types";

const initialState = {
  eduCenter: [],
  loading: false,
  error: null,
  success: null
};

const eduCenterReducer = (state = initialState, action) => {
  switch (action.type) {
      case FETCH_EDU_CENTER_STARTED:
          return {
              ...state,
              loading: true
          };
      case FETCH_EDU_CENTER_SUCCESS:
          return {
              ...state,
              eduCenter: action.payload.eduCenter,
              loading: false,
              error: null
          };
      case FETCH_EDU_CENTER_FAILURE:
          return {
              ...state,
              loading: false,
              error: action.payload.error,
              success: null
          };
      case CREATE_EDU_CENTER_SUCCESS:
          return {
              ...state,
              eduCenter: [action.payload.addedEduCenter, ...state.eduCenter],
              error: null,
              success: action.payload.successMessage
          };
      case CREATE_EDU_CENTER_FAILURE:
          return {
              ...state,
              error: action.payload.error,
              success: null
          };
      case DELETE_EDU_CENTER_SUCCESS:
          return {
              ...state,
              eduCenter: state.eduCenter.filter(
                  item => item._id !== action.payload.id
              ),
              error: null,
              success: action.payload.successMessage
          };
      case DELETE_EDU_CENTER_FAILURE:
          return {
              ...state,
              error: action.payload.error,
              success: null
          };
      case UPDATE_EDU_CENTER_SUCCESS:
          return {
              ...state,
              eduCenter: state.eduCenter.map(item => {
                  if (item._id === action.payload.id) {
                      return action.payload.updatedEduCenter;
                  }
                  return item;
              }),
              error: null,
              success: action.payload.successMessage
          };
      case UPDATE_EDU_CENTER_FAILURE:
          return {
              ...state,
              error: action.payload.error,
              success: null
          };

      default:
          return state;
  }
};

export default eduCenterReducer;
