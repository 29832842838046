import {
    FETCH_TOTAL_SALES_STARTED,
    FETCH_TOTAL_SALES_SUCCESS,
    FETCH_TOTAL_SALES_FAILURE
  } from "../actions/types";
  
  const initialState = {
    allOrders: [],
    noOfUsers: 0,
    noOfSales: 0,
    amountOfSales: 0,
    pagesCount: null,
    loading: false,
    error: null,
    success: null
  };
  
  const indicatorsReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_TOTAL_SALES_STARTED:
        return {
          ...state,
          loading: true
        };
      case FETCH_TOTAL_SALES_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
          allOrders: action.payload.allOrders,
          noOfUsers: action.payload.noOfUsers,
          noOfSales: action.payload.noOfSales,
          amountOfSales: action.payload.amountOfSales,
          pagesCount: action.payload.pagesCount

        };
      case FETCH_TOTAL_SALES_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload.error,
          success: null
        };
  
      default:
        return state;
    }
  };
  
  export default indicatorsReducer;