import {
  FETCH_ALL_USERS_STARTED,
  FETCH_ALL_USERS_SUCCESS,
  FETCH_ALL_USERS_FAILURE,
  SEARCH_MEMBER_STARTED,
  SEARCH_MEMBER_SUCCESS,
  SEARCH_MEMBER_FAILURE,
  // ADMIN_PERMISSION_SUCCESS,
  // ADMIN_PERMISSION_FAILURE,
  STRICT_USER_SUCCESS,
  STRICT_USER_FAILURE,
  CHANGE_DONE_FOR_MEMBER_SUCCESS,
  CHANGE_DONE_FOR_MEMBER_FAILURE
} from "../actions/types";

let initialState = {
  allUsers: [],
  // allAdmins: [],
  pagesCount: null,
  loading: null,
  success: null,
  error: null
};

const permissionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_USERS_STARTED:
      return {
        ...state,
        loading: true,
        success: null,
        error: null
      };
    case FETCH_ALL_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        allUsers: action.payload.allUsers,
        // allAdmins: action.payload.allAdmins,
        pagesCount: action.payload.pagesCount,
        success: null,
        error: null
      };
    case FETCH_ALL_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        success: null
      };


      case SEARCH_MEMBER_STARTED:
        return {
          ...state,
          loading: true,
          success: null,
          error: null
        };
      case SEARCH_MEMBER_SUCCESS:
        return {
          ...state,
          loading: false,
          allUsers: action.payload.allUsers,
          pagesCount: action.payload.pagesCount,
          success: null,
          error: null
        };
      case SEARCH_MEMBER_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload.error,
          success: null
        };


    // case ADMIN_PERMISSION_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     allUsers: state.allUsers.map(user => {
    //       if (user._id === action.payload.user._id) {
    //         return action.payload.user;
    //       }
    //       return user;
    //     }),
    //     success: action.payload.message,
    //     error: null
    //   };
    // case ADMIN_PERMISSION_FAILURE:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.payload.error,
    //     success: null
    //   };
    case STRICT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        allUsers: state.allUsers.map(user => {
          if (user._id === action.payload.user._id) {
            return action.payload.user;
          }
          return user;
        }),
        success: action.payload.message,
        error: null
      };
    case STRICT_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        success: null
      };
  case CHANGE_DONE_FOR_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        allUsers: state.allUsers.map(user => {
          if (user._id === action.payload.user._id) {
            return action.payload.user;
          }
          return user;
        }),
        success: action.payload.message,
        error: null
      };
    case CHANGE_DONE_FOR_MEMBER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        success: null
      };
    default:
      return state;
  }
};

export default permissionsReducer;
