import {
    FETCH_SHOPPING_COINS_STARTED,
    FETCH_SHOPPING_COINS_SUCCESS,
    FETCH_SHOPPING_COINS_FAILURE
  } from "../actions/types";
  
  const initialState = {
    totalShoppingCoins: null,
    earnedShoppingCoinsByMonth : null,
    pagesCount: null,
    allShoppingCoins: [],
    loading: false,
    error: null,
    success: null
  };
  
  const shoppingCoinsReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_SHOPPING_COINS_STARTED:
        return {
          ...state,
          loading: true
        };
      case FETCH_SHOPPING_COINS_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
          allShoppingCoins: action.payload.allShoppingCoins,
          earnedShoppingCoinsByMonth: action.payload.earnedShoppingCoinsByMonth,
          totalShoppingCoins: action.payload.totalShoppingCoins,
          pagesCount: action.payload.pagesCount


        };
      case FETCH_SHOPPING_COINS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload.error,
          success: null
        };
  
      default:
        return state;
    }
  };
  
  export default shoppingCoinsReducer;