import {
  FETCH_CART_STARTED,
  FETCH_CART_SUCCESS,
  FETCH_CART_FAILURE,
  ADD_TO_CART_SUCCESS,
  ADD_TO_CART_FAILURE,
  DELETE_FROM_CART_STARTED,
  DELETE_FROM_CART_SUCCESS,
  DELETE_FROM_CART_FAILURE,
  CHANGE_CART_QUANTITY_STARTED,
  CHANGE_CART_QUANTITY_SUCCESS,
  CHANGE_CART_QUANTITY_FAILURE,
  CHOOSE_ORDER_ADDRESS_STARTED,
  CHOOSE_ORDER_ADDRESS_SUCCESS,
  CHOOSE_ORDER_ADDRESS_FAILURE,
  CHECK_CART_ITEM_STARTED,
  CHECK_CART_ITEM_SUCCESS,
  CHECK_CART_ITEM_FAILURE,
  REDEEM_USER_VOUCHER_STARTED,
  REDEEM_USER_VOUCHER_SUCCESS,
  REDEEM_USER_VOUCHER_FAILURE,
} from "./../actions/types";

const initialState = {
  cart: [],
  voucher:[],
  totalPrice: 0,
  totalBV: 0,
  coinsRedeemed:0,
  shippingFee:0,
  paidAmount:0,
  voucherRedeemed:0,
  loading: false,
  success: null,
  error: null
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CART_STARTED:
      return {
        ...state,
        loading: true
      };
    case FETCH_CART_SUCCESS:
      return {
        ...state,
        cart: action.payload.cart.items,
        voucher: action.payload.cart.voucher,
        totalPrice: action.payload.cart.totalPrice,
        totalBV: action.payload.cart.totalBV,
        coinsRedeemed: action.payload.cart.coinsRedeemed,
        shippingFee: action.payload.cart.shippingFee,
        voucherRedeemed: action.payload.cart.voucherRedeemed,
        paidAmount: action.payload.cart.paidAmount,
        loading: false
      };
    case FETCH_CART_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        success: null,
        loading: false
      };
    case ADD_TO_CART_SUCCESS:
      return {
        ...state,
        cart: action.payload.cart,
        loading: false,
        success: action.payload.message,
        error: null
      };
    case ADD_TO_CART_FAILURE:
      return {
        ...state,
        loading: false,
        success: null,
        error: action.payload.error
      };
      case DELETE_FROM_CART_STARTED:
        return {
          ...state,
          loading: true
        };
    case DELETE_FROM_CART_SUCCESS:
      return {
        ...state,
        cart: action.payload.cart.items,
        voucher: action.payload.cart.voucher,
        totalPrice: action.payload.cart.totalPrice,
        totalBV: action.payload.cart.totalBV,
        coinsRedeemed: action.payload.cart.coinsRedeemed,
        shippingFee: action.payload.cart.shippingFee,
        voucherRedeemed: action.payload.cart.voucherRedeemed,
        paidAmount: action.payload.cart.paidAmount,
        loading: false,
        success: action.payload.message,
        error: null
      };
    case DELETE_FROM_CART_FAILURE:
      return {
        ...state,
        loading: false,
        success: null,
        error: action.payload.error
      };
      case CHANGE_CART_QUANTITY_STARTED:
        return {
          ...state,
          loading: true
        };
    case CHANGE_CART_QUANTITY_SUCCESS:
      return {
        ...state,
        cart: action.payload.cart.items,
        voucher: action.payload.cart.voucher,
        totalPrice: action.payload.cart.totalPrice,
        totalBV: action.payload.cart.totalBV,
        coinsRedeemed: action.payload.cart.coinsRedeemed,
        shippingFee: action.payload.cart.shippingFee,
        voucherRedeemed: action.payload.cart.voucherRedeemed,
        paidAmount: action.payload.cart.paidAmount,
        loading: false,
        success: action.payload.message,
        error: null
      };
    case CHANGE_CART_QUANTITY_FAILURE:
      return {
        ...state,
        loading: false,
        success: null,
        error: action.payload.error
      };
      case CHOOSE_ORDER_ADDRESS_STARTED:
        return {
          ...state,
          loading: true
        };

      case CHOOSE_ORDER_ADDRESS_SUCCESS:
        return {
          ...state,
          cart: action.payload.cart.items,
          voucher: action.payload.cart.voucher,
          totalPrice: action.payload.cart.totalPrice,
          totalBV: action.payload.cart.totalBV,
          coinsRedeemed: action.payload.cart.coinsRedeemed,
          shippingFee: action.payload.cart.shippingFee,
          voucherRedeemed: action.payload.cart.voucherRedeemed,
          paidAmount: action.payload.cart.paidAmount,
          loading: false
        };
      case CHOOSE_ORDER_ADDRESS_FAILURE:
        return {
          ...state,
          error: action.payload.error,
          success: null,
          loading: false
        };
      case CHECK_CART_ITEM_STARTED:
          return {
            ...state,
            loading: true
          };
      case CHECK_CART_ITEM_SUCCESS:
        return {
          ...state,
          cart: action.payload.cart.items,
          voucher: action.payload.cart.voucher,
          totalPrice: action.payload.cart.totalPrice,
          totalBV: action.payload.cart.totalBV,
          coinsRedeemed: action.payload.cart.coinsRedeemed,
          shippingFee: action.payload.cart.shippingFee,
          voucherRedeemed: action.payload.cart.voucherRedeemed,
          paidAmount: action.payload.cart.paidAmount,
          loading: false,
          success: action.payload.message,
          error: null
        };
      case CHECK_CART_ITEM_FAILURE:
        return {
          ...state,
          loading: false,
          success: null,
          error: action.payload.error
        };
        case REDEEM_USER_VOUCHER_STARTED:
          return {
            ...state,
            loading: true
          };
      case REDEEM_USER_VOUCHER_SUCCESS:
        return {
          ...state,
          cart: action.payload.cart.items,
          voucher: action.payload.cart.voucher,
          totalPrice: action.payload.cart.totalPrice,
          totalBV: action.payload.cart.totalBV,
          coinsRedeemed: action.payload.cart.coinsRedeemed,
          shippingFee: action.payload.cart.shippingFee,
          voucherRedeemed: action.payload.cart.voucherRedeemed,
          paidAmount: action.payload.cart.paidAmount,
          loading: false,
          success: action.payload.message,
          error: null
        };
      case REDEEM_USER_VOUCHER_FAILURE:
        return {
          ...state,
          loading: false,
          success: null,
          error: action.payload.error
        };
    default:
      return state;
  }
};

export default cartReducer;
